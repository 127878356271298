<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    v-model="selected"
    :items="items"
    :loading="loading"
    clearable
    item-color="grey darken-4"
    :item-text="getLeaveTypeName"
    item-value="id"
    :label="$t('leave.type')"
  >
    <template slot="selection" slot-scope="data">
      {{ data.item.leavePolicy.leaveType.name }}
    </template>
    <template slot="item" slot-scope="data">
      <v-list-item-avatar size="36" :color="data.item.leavePolicy.leaveType.color">
        <v-icon size="18" dark color="white">{{ data.item.leavePolicy.leaveType.icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="data.item.leavePolicy.leaveType.name" />
        <v-list-item-subtitle
          >{{ $t("leave.request.used") }}: {{ data.item.used.toFixed(2) }} / {{ $t("leave.request.remaining") }}:
          {{
            (data.item.previousBalance + data.item.leavePolicy.accrualCalculatedCount - data.item.used).toFixed(2)
          }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description leave-type-picker Component Logic
   * @date 4.08.2020
   */

  import { EMPLOYEE_LEAVE_ACCRUALS } from "../../query";

  export default {
    inheritAttrs: false,
    name: "leave-type-picker",
    props: {
      value: [String, Object],
      beginDate: String,
      leaveRequestType: String
    },
    data: () => ({
      loading: false,
      items: [],
      selected: null
    }),
    watch: {
      value: {
        handler(v) {
          this.selected = v;
        },
        immediate: true
      },
      beginDate: {
        handler(v) {
          if (v) {
            this.fetchItems();
          } else {
            this.items = [];
          }
        },
        immediate: true
      }
    },
    methods: {
      async fetchItems() {
        this.loading = true;
        await this.$apollo
          .query({
            query: EMPLOYEE_LEAVE_ACCRUALS,
            variables: {
              employee: this.$store.state.auth.user.id,
              beginDate: this.beginDate,
              daily: this.leaveRequestType === "FULL_DAY"
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.items = data.leaveAccruals.filter(accruals => {
                return accruals.leavePolicy.leaveType.restriction.requestable === true;
              });
            }
          })
          .catch(e => this.$helpers.showNotification(e.message))
          .finally(() => (this.loading = false));
      },
      getLeaveTypeName(item) {
        return item.leavePolicy.leaveType.name;
      }
    }
  };
</script>

<style scoped></style>
